.plasmic_tokens {
  --token-iWQmILTzm3N0: var(--antd-colorPrimary);
  --plasmic-token-system-primary: var(--token-iWQmILTzm3N0);
  --token-HyiizzCtnTod: var(--antd-colorSuccess);
  --plasmic-token-system-success: var(--token-HyiizzCtnTod);
  --token-DUPkVtvXeJJo: var(--antd-colorWarning);
  --plasmic-token-system-warning: var(--token-DUPkVtvXeJJo);
  --token-fDNuFs9Skt3p: var(--antd-colorError);
  --plasmic-token-system-error: var(--token-fDNuFs9Skt3p);
  --token-CtWQF2P39aLo: var(--antd-colorInfo);
  --plasmic-token-system-info: var(--token-CtWQF2P39aLo);
  --token-b54tAmlgUaXe: var(--antd-colorText);
  --plasmic-token-system-text: var(--token-b54tAmlgUaXe);
  --token-qlDkklux5JrT: var(--antd-colorTextSecondary);
  --plasmic-token-system-text-secondary: var(--token-qlDkklux5JrT);
  --token-Unt2O2MnUneb: var(--antd-colorTextTertiary);
  --plasmic-token-system-text-tertiary: var(--token-Unt2O2MnUneb);
  --token-ehVEIAjb9HeO: var(--antd-colorTextQuaternary);
  --plasmic-token-system-text-quaternary: var(--token-ehVEIAjb9HeO);
  --token-pyhDq7s0RTkz: var(--antd-colorBorder);
  --plasmic-token-system-border: var(--token-pyhDq7s0RTkz);
  --token-Xzi5OT07e2_1: var(--antd-colorBorderSecondary);
  --plasmic-token-system-border-secondary: var(--token-Xzi5OT07e2_1);
  --token-iSm9mh5cC952: var(--antd-colorFill);
  --plasmic-token-system-fill: var(--token-iSm9mh5cC952);
  --token-pLZthFInDOSZ: var(--antd-colorFillSecondary);
  --plasmic-token-system-fill-secondary: var(--token-pLZthFInDOSZ);
  --token-PFNQE33ELh3b: var(--antd-colorFillTertiary);
  --plasmic-token-system-fill-tertiary: var(--token-PFNQE33ELh3b);
  --token-MeZ_ciy2nAhO: var(--antd-colorFillQuaternary);
  --plasmic-token-system-fill-quaternary: var(--token-MeZ_ciy2nAhO);
  --token-as44I7WwR-rZ: var(--antd-colorBgLayout);
  --plasmic-token-system-bg-layout: var(--token-as44I7WwR-rZ);
  --token-41VRhDKfihhY: var(--antd-colorBgContainer);
  --plasmic-token-system-bg-container: var(--token-41VRhDKfihhY);
  --token-GGYj13plNx6v: var(--antd-colorBgElevated);
  --plasmic-token-system-bg-elevated: var(--token-GGYj13plNx6v);
  --token-WxvvgatuPYoJ: var(--antd-colorBgSpotlight);
  --plasmic-token-system-bg-spotlight: var(--token-WxvvgatuPYoJ);
  --token-lIvc0WM5-V4N: var(--antd-colorPrimaryBg);
  --plasmic-token-system-primary-bg: var(--token-lIvc0WM5-V4N);
  --token-WxyGMLCV1Pdq: var(--antd-colorPrimaryBgHover);
  --plasmic-token-system-primary-bg-hover: var(--token-WxyGMLCV1Pdq);
  --token-IlR0iqdnKI_m: var(--antd-colorPrimaryBorder);
  --plasmic-token-system-primary-border: var(--token-IlR0iqdnKI_m);
  --token-7yzgT3X0hxHm: var(--antd-colorPrimaryBorderHover);
  --plasmic-token-system-primary-border-hover: var(--token-7yzgT3X0hxHm);
  --token-zSc5dQE2YXMN: var(--antd-colorPrimaryHover);
  --plasmic-token-system-primary-hover: var(--token-zSc5dQE2YXMN);
  --token-5OZEb1MGGCI4: var(--antd-colorPrimaryActive);
  --plasmic-token-system-primary-active: var(--token-5OZEb1MGGCI4);
  --token-7EicNAKGWsev: var(--antd-colorPrimaryTextHover);
  --plasmic-token-system-primary-text-hover: var(--token-7EicNAKGWsev);
  --token-jULyYuL3e-TL: var(--antd-colorPrimaryText);
  --plasmic-token-system-primary-text: var(--token-jULyYuL3e-TL);
  --token-fyoD0poQfxvl: var(--antd-colorPrimaryTextActive);
  --plasmic-token-system-primary-text-active: var(--token-fyoD0poQfxvl);
  --token-oh_zdnnQ9zjm: var(--antd-colorSuccessBg);
  --plasmic-token-system-success-bg: var(--token-oh_zdnnQ9zjm);
  --token-aTHxjKoUeWnv: var(--antd-colorSuccessBgHover);
  --plasmic-token-system-success-bg-hover: var(--token-aTHxjKoUeWnv);
  --token-yY5WOXi2jAzm: var(--antd-colorSuccessBorder);
  --plasmic-token-system-success-border: var(--token-yY5WOXi2jAzm);
  --token-_5gBev-c4IE1: var(--antd-colorSuccessBorderHover);
  --plasmic-token-system-success-border-hover: var(--token-_5gBev-c4IE1);
  --token-EfBA1Z63plMe: var(--antd-colorSuccessHover);
  --plasmic-token-system-success-hover: var(--token-EfBA1Z63plMe);
  --token-Z7tAkk9KA7lq: var(--antd-colorSuccessActive);
  --plasmic-token-system-success-active: var(--token-Z7tAkk9KA7lq);
  --token-N44WQybcxHnK: var(--antd-colorSuccessTextHover);
  --plasmic-token-system-success-text-hover: var(--token-N44WQybcxHnK);
  --token--9SFkh0xiZqQ: var(--antd-colorSuccessText);
  --plasmic-token-system-success-text: var(--token--9SFkh0xiZqQ);
  --token-hrMucCmuVP2a: var(--antd-colorSuccessTextActive);
  --plasmic-token-system-success-text-active: var(--token-hrMucCmuVP2a);
  --token-SXG6cjnWZyNC: var(--antd-colorWarningBg);
  --plasmic-token-system-warning-bg: var(--token-SXG6cjnWZyNC);
  --token-arm2L2XW_xJ6: var(--antd-colorWarningBgHover);
  --plasmic-token-system-warning-bg-hover: var(--token-arm2L2XW_xJ6);
  --token-dZTqA8w80eW1: var(--antd-colorWarningBorder);
  --plasmic-token-system-warning-border: var(--token-dZTqA8w80eW1);
  --token-LCC99oGt2mlX: var(--antd-colorWarningBorderHover);
  --plasmic-token-system-warning-border-hover: var(--token-LCC99oGt2mlX);
  --token-3GX45hKJgVgM: var(--antd-colorWarningHover);
  --plasmic-token-system-warning-hover: var(--token-3GX45hKJgVgM);
  --token-Bjjq_24AWhes: var(--antd-colorWarningActive);
  --plasmic-token-system-warning-active: var(--token-Bjjq_24AWhes);
  --token-bASdKwkcGC-_: var(--antd-colorWarningTextHover);
  --plasmic-token-system-warning-text-hover: var(--token-bASdKwkcGC-_);
  --token-LqD8W4lcEdIj: var(--antd-colorWarningText);
  --plasmic-token-system-warning-text: var(--token-LqD8W4lcEdIj);
  --token-MAnhFXGh8Ir8: var(--antd-colorWarningTextActive);
  --plasmic-token-system-warning-text-active: var(--token-MAnhFXGh8Ir8);
  --token-oDTVzkxdJ872: var(--antd-colorInfoBg);
  --plasmic-token-system-info-bg: var(--token-oDTVzkxdJ872);
  --token-g_VDjIuHq0U5: var(--antd-colorInfoBgHover);
  --plasmic-token-system-info-bg-hover: var(--token-g_VDjIuHq0U5);
  --token-mQuOloHwx25-: var(--antd-colorInfoBorder);
  --plasmic-token-system-info-border: var(--token-mQuOloHwx25-);
  --token-ytBWtTwx11rx: var(--antd-colorInfoBorderHover);
  --plasmic-token-system-info-border-hover: var(--token-ytBWtTwx11rx);
  --token-ReYtjy5W-zM7: var(--antd-colorInfoHover);
  --plasmic-token-system-info-hover: var(--token-ReYtjy5W-zM7);
  --token-2xPkbw4aFzX2: var(--antd-colorInfoActive);
  --plasmic-token-system-info-active: var(--token-2xPkbw4aFzX2);
  --token-bywOirYXcruc: var(--antd-colorInfoTextHover);
  --plasmic-token-system-info-text-hover: var(--token-bywOirYXcruc);
  --token-uKRz3ov0rqIf: var(--antd-colorInfoText);
  --plasmic-token-system-info-text: var(--token-uKRz3ov0rqIf);
  --token-ADrp2mfNmuLA: var(--antd-colorInfoTextActive);
  --plasmic-token-system-info-text-active: var(--token-ADrp2mfNmuLA);
  --token-4ccrUZ-M3zhx: var(--antd-colorErrorBg);
  --plasmic-token-system-error-bg: var(--token-4ccrUZ-M3zhx);
  --token-p9OxE4l9Com5: var(--antd-colorErrorBgHover);
  --plasmic-token-system-error-bg-hover: var(--token-p9OxE4l9Com5);
  --token-P02GAf-HlHok: var(--antd-colorErrorBorder);
  --plasmic-token-system-error-border: var(--token-P02GAf-HlHok);
  --token-0gAjYifG8xWY: var(--antd-colorErrorBorderHover);
  --plasmic-token-system-error-border-hover: var(--token-0gAjYifG8xWY);
  --token-_u6T-ctQmwmL: var(--antd-colorErrorHover);
  --plasmic-token-system-error-hover: var(--token-_u6T-ctQmwmL);
  --token-j-ZwOKbpUCqd: var(--antd-colorErrorActive);
  --plasmic-token-system-error-active: var(--token-j-ZwOKbpUCqd);
  --token-V6MqwrLdbww7: var(--antd-colorErrorTextHover);
  --plasmic-token-system-error-text-hover: var(--token-V6MqwrLdbww7);
  --token-ddpevpfGL4QK: var(--antd-colorErrorText);
  --plasmic-token-system-error-text: var(--token-ddpevpfGL4QK);
  --token-HxnmpURJ0E1r: var(--antd-colorErrorTextActive);
  --plasmic-token-system-error-text-active: var(--token-HxnmpURJ0E1r);
  --token-O-63NKRcNhLJ: var(--antd-colorWhite);
  --plasmic-token-system-white: var(--token-O-63NKRcNhLJ);
  --token-2aHIw8xI62Xi: var(--antd-colorBgMask);
  --plasmic-token-system-bg-mask: var(--token-2aHIw8xI62Xi);
  --token-TpmbTrq5hCAj: var(--antd-colorIcon);
  --plasmic-token-system-icon: var(--token-TpmbTrq5hCAj);
  --token-hb610yGAurc2: var(--antd-colorIconHover);
  --plasmic-token-system-icon-hover: var(--token-hb610yGAurc2);
  --token-lLkc5xWMZR34: var(--antd-colorLink);
  --plasmic-token-system-link: var(--token-lLkc5xWMZR34);
  --token-wSnKEwu5y_PF: var(--antd-colorLinkHover);
  --plasmic-token-system-link-hover: var(--token-wSnKEwu5y_PF);
  --token-o9SjJ7GuRCZJ: var(--antd-paddingXXS);
  --plasmic-token-system-padding-xxs: var(--token-o9SjJ7GuRCZJ);
  --token-MlCXSE1p_B5w: var(--antd-paddingXS);
  --plasmic-token-system-padding-xs: var(--token-MlCXSE1p_B5w);
  --token-o3y7S9gx0ndk: var(--antd-paddingSM);
  --plasmic-token-system-padding-sm: var(--token-o3y7S9gx0ndk);
  --token-GnePloX-BYUf: var(--antd-padding);
  --plasmic-token-system-padding-m: var(--token-GnePloX-BYUf);
  --token-pgaudsfqNIH0: var(--antd-paddingMD);
  --plasmic-token-system-padding-md: var(--token-pgaudsfqNIH0);
  --token-hfUqRMNdS5wL: var(--antd-paddingLG);
  --plasmic-token-system-padding-lg: var(--token-hfUqRMNdS5wL);
  --token-0aJ0ExCGAHaV: var(--antd-paddingXL);
  --plasmic-token-system-padding-xl: var(--token-0aJ0ExCGAHaV);
  --token-fCBRzfPOBNOL: var(--antd-marginXXS);
  --plasmic-token-system-margin-xxs: var(--token-fCBRzfPOBNOL);
  --token-dT2WOkFV5Mee: var(--antd-marginXS);
  --plasmic-token-system-margin-xs: var(--token-dT2WOkFV5Mee);
  --token-5qBYYrKVDrlB: var(--antd-marginSM);
  --plasmic-token-system-margin-sm: var(--token-5qBYYrKVDrlB);
  --token-rX_8jNE36opk: var(--antd-margin);
  --plasmic-token-system-margin-m: var(--token-rX_8jNE36opk);
  --token-Gtd-oD4SfydH: var(--antd-marginMD);
  --plasmic-token-system-margin-md: var(--token-Gtd-oD4SfydH);
  --token-sPi98nT-JyUw: var(--antd-marginLG);
  --plasmic-token-system-margin-lg: var(--token-sPi98nT-JyUw);
  --token-PUpYMghDbUAb: var(--antd-marginXL);
  --plasmic-token-system-margin-xl: var(--token-PUpYMghDbUAb);
  --token-3li6WPFiJWAX: var(--antd-marginXXL);
  --plasmic-token-system-margin-xxl: var(--token-3li6WPFiJWAX);
  --token-fwZvoB_ZCfwn: var(--antd-fontSize);
  --plasmic-token-system-m: var(--token-fwZvoB_ZCfwn);
  --token-S57U-ojSMftk: var(--antd-fontSizeSM);
  --plasmic-token-system-sm: var(--token-S57U-ojSMftk);
  --token-LGg-zkf2M2GB: var(--antd-fontSizeLG);
  --plasmic-token-system-lg: var(--token-LGg-zkf2M2GB);
  --token-H1BaJ8q_hk3H: var(--antd-fontSizeXL);
  --plasmic-token-system-xl: var(--token-H1BaJ8q_hk3H);
  --token-IvcMj9ZyTMo8: var(--antd-fontSizeHeading1);
  --plasmic-token-system-heading-1: var(--token-IvcMj9ZyTMo8);
  --token-KlrAoGIT1za7: var(--antd-fontSizeHeading2);
  --plasmic-token-system-heading-2: var(--token-KlrAoGIT1za7);
  --token-16_cC9w3e0Nk: var(--antd-fontSizeHeading3);
  --plasmic-token-system-heading-3: var(--token-16_cC9w3e0Nk);
  --token-hCgNygAysIbb: var(--antd-fontSizeHeading4);
  --plasmic-token-system-heading-4: var(--token-hCgNygAysIbb);
  --token-70Yb0tqelyc1: var(--antd-fontSizeHeading5);
  --plasmic-token-system-heading-5: var(--token-70Yb0tqelyc1);
  --token-snVsVaM8n5_4: var(--antd-lineHeight);
  --plasmic-token-system-m: var(--token-snVsVaM8n5_4);
  --token-jb8CFgMUZa8u: var(--antd-lineHeightLG);
  --plasmic-token-system-lg: var(--token-jb8CFgMUZa8u);
  --token-SVc6Y_f02A4U: var(--antd-lineHeightSM);
  --plasmic-token-system-sm: var(--token-SVc6Y_f02A4U);
  --token-xwvpf2CK6_-R: var(--antd-lineHeightHeading1);
  --plasmic-token-system-heading-1: var(--token-xwvpf2CK6_-R);
  --token-bJEU6Bg7WMDd: var(--antd-lineHeightHeading2);
  --plasmic-token-system-heading-2: var(--token-bJEU6Bg7WMDd);
  --token-9aYtHZjcigNn: var(--antd-lineHeightHeading3);
  --plasmic-token-system-heading-3: var(--token-9aYtHZjcigNn);
  --token-5UxZgePJMjDS: var(--antd-lineHeightHeading4);
  --plasmic-token-system-heading-4: var(--token-5UxZgePJMjDS);
  --token-NIHokAJdLa88: var(--antd-lineHeightHeading5);
  --plasmic-token-system-heading-5: var(--token-NIHokAJdLa88);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
}
